<script setup>

import { onMounted, ref } from "vue"
import { decimal, required } from "@vuelidate/validators"
import { useRoute } from 'vue-router'
import { useToast } from "primevue/usetoast"
import { useVuelidate } from "@vuelidate/core"
import { useActivitiesStore } from "@/store/activity"

import ActivityAccountStatusOfMember from "@/components-uhas/activities/ActivityAccountStatusOfMember"

const route = useRoute()
const toast = useToast()

const activityStore = useActivitiesStore()

const activityMemberExness = ref({})
const activityMemberXm = ref({})

const loading = ref(true)

const modelExness = ref({})
const modelXm = ref({})

const rules = {
  abs_gain: { decimal },
  drawdown: { decimal },
  equity: { decimal },
  is_disabled_connect: { required },
}

const vExness$ = useVuelidate(rules, modelExness)
const vXm$ = useVuelidate(rules, modelXm)

const initial = () => {
  const id = route.params.id
  const memberId = route.params.memberId

  activityStore.showMember(id, memberId).then((res) => {
    activityMemberExness.value = res.data.activity_members.find(x => x.provider === 'exness')
    activityMemberXm.value = res.data.activity_members.find(x => x.provider === 'xm')

    if (activityMemberExness.value) {
      modelExness.value = {
        id: activityMemberExness.value.id,
        abs_gain: parseFloat(activityMemberExness.value.stats_for_calculate?.abs_gain) || null,
        drawdown: parseFloat(activityMemberExness.value.stats_for_calculate?.drawdown) || null,
        equity: parseFloat(activityMemberExness.value.stats_for_calculate?.equity) || null,
        is_disabled_connect: activityMemberExness.value.is_disabled_connect,
      }
    }

    if (activityMemberXm.value) {
      modelXm.value = {
        id: activityMemberXm.value.id,
        abs_gain: parseFloat(activityMemberXm.value.stats_for_calculate?.abs_gain) || null,
        drawdown: parseFloat(activityMemberXm.value.stats_for_calculate?.drawdown) || null,
        equity: parseFloat(activityMemberXm.value.stats_for_calculate?.equity) || null,
        is_disabled_connect: activityMemberXm.value.is_disabled_connect,
      }
    }

    loading.value = false
  })
}

const save = (model) => {
  loading.value = true

  const id = route.params.id
  const memberId = route.params.memberId

  activityStore.updateMember(id, memberId, model).then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      loading.value = false
      return
    }

    initial()

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  }).finally(() => {
    loading.value = false
  })
}

onMounted(() => {
  initial()
})

</script>

<template>
  <div class="block-section">
    <div class="block-header">
        <span class="block-title">
          <div>Show Activity #{{ route.params.id }} Member #{{ route.params.memberId }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>

        <br/>
    </div>

    <div v-if="activityMemberExness" class="block-content">
      <div class="grid">
        <div class="col-12 sm:col-6">
          <div class="card">
            <h5>Information Exness</h5>
            <label class="block text-900 font-medium mb-2" for="server">Server</label>
            <InputText :value="activityMemberExness.server" id="server" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="login">Login</label>
            <InputText :value="activityMemberExness.login" id="login" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="password">Password</label>
            <InputText :value="activityMemberExness.password" id="password" rows="5" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="connected_at">Connected At</label>
            <InputText :value="activityMemberExness.connected_at ? new Date(activityMemberExness.connected_at).toLocaleString() : ''" id="connected_at" rows="5" class="w-full mb-3" readonly />
            
            <label class="block text-900 font-medium mb-2" for="connecting_at">Connecting At</label>
            <InputText :value="activityMemberExness.connecting_at ? new Date(activityMemberExness.connecting_at).toLocaleString() : ''" id="connecting_at" rows="5" class="w-full mb-3" readonly />
            
            <label class="block text-900 font-medium mb-2" for="reason_connection_failure">Reason connection failure</label>
            <InputText :value="activityMemberExness.reason_connection_failure" id="reason_connection_failure" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
            <InputText :value="activityMemberExness.created_at ? new Date(activityMemberExness.created_at).toLocaleString() : ''" id="created_at" rows="5" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
            <InputText :value="activityMemberExness.updated_at ? new Date(activityMemberExness.updated_at).toLocaleString() : ''" id="updated_at" rows="5" class="w-full mb-3" readonly />

          </div>
        </div>

        <div class="col-12 sm:col-6">
          <div class="card">
            <h5>Stats Exness (#{{ activityMemberExness.id }})</h5>

            <activity-account-status-of-member :data="activityMemberExness" />
            <div class="m-3" />
            
            <label class="block text-900 font-medium mb-2" for="score">Score</label>
            <InputText :value="activityMemberExness.score" id="score" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="is_disabled_connect">Disabled to connect</label>
            <InputSwitch v-model="vExness$.is_disabled_connect.$model" id="is_disabled_connect" class="mb-3" />

            <label class="block text-900 font-medium mb-2" for="abs_gain">Abs. Gain (%)</label>
            <InputNumber v-model="vExness$.abs_gain.$model" id="abs_gain" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="['mb-3 w-full', vExness$.abs_gain.$invalid ? 'p-invalid' : '']"/>

            <label class="block text-900 font-medium mb-2" for="drawdown">Drawdown (%)</label>
            <InputNumber v-model="vExness$.drawdown.$model" id="drawdown" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="['mb-3 w-full', vExness$.drawdown.$invalid ? 'p-invalid' : '']"/>

            <label class="block text-900 font-medium mb-2" for="equity">Equity</label>
            <InputNumber v-model="vExness$.equity.$model" id="equity" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="['mb-3 w-full', vExness$.equity.$invalid ? 'p-invalid' : '']"/>

            <Button label="Save" class="w-full" :loading="loading" @click="save(modelExness)" />

            <div class="m-3" />

            <div class="card">
              <div class="card-body">
                <label class="block text-900 font-medium mb-2" for="gain">Gain</label>
                <InputText :value="activityMemberExness.stats?.['gain']" id="gain" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="absGain">Abs. Gain</label>
                <InputText :value="activityMemberExness.stats?.['absGain']" id="absGain" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="daily">Daily</label>
                <InputText :value="activityMemberExness.stats?.['daily']" id="daily" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="monthly">Monthly</label>
                <InputText :value="activityMemberExness.stats?.['monthly']" id="monthly" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="withdrawals">Withdrawals</label>
                <InputText :value="activityMemberExness.stats?.['withdrawals']" id="withdrawals" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="deposits">Deposits</label>
                <InputText :value="activityMemberExness.stats?.['deposits']" id="deposits" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="interest">Interest</label>
                <InputText :value="activityMemberExness.stats?.['interest']" id="interest" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="profit">Profit</label>
                <InputText :value="activityMemberExness.stats?.['profit']" id="profit" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="balance">Balance</label>
                <InputText :value="activityMemberExness.stats?.['balance']" id="balance" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="drawdown">Drawdown</label>
                <InputText :value="activityMemberExness.stats?.['drawdown']" id="drawdown" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="equity">Equity</label>
                <InputText :value="activityMemberExness.stats?.['equity']" id="equity" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="equityPercent">Equity Percent</label>
                <InputText :value="activityMemberExness.stats?.['equityPercent']" id="equityPercent" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="demo">Demo</label>
                <InputText :value="activityMemberExness.stats?.['demo']" id="demo" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="lastUpdateDate">Last Update Date</label>
                <InputText :value="activityMemberExness.stats?.['lastUpdateDate']" id="lastUpdateDate" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="creationDate">Creation Date</label>
                <InputText :value="activityMemberExness.stats?.['creationDate']" id="creationDate" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="firstTradeDate">First Trade Date</label>
                <InputText :value="activityMemberExness.stats?.['firstTradeDate']" id="firstTradeDate" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="tracking">Tracking</label>
                <InputText :value="activityMemberExness.stats?.['tracking']" id="tracking" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="views">Views</label>
                <InputText :value="activityMemberExness.stats?.['views']" id="views" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="commission">Commission</label>
                <InputText :value="activityMemberExness.stats?.['commission']" id="commission" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="currency">Currency</label>
                <InputText :value="activityMemberExness.stats?.['currency']" id="currency" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="profitFactor">Profit Factor</label>
                <InputText :value="activityMemberExness.stats?.['profitFactor']" id="profitFactor" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="pips">Pips</label>
                <InputText :value="activityMemberExness.stats?.['pips']" id="pips" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="invitationUrl">Invitation Url</label>
                <InputText :value="activityMemberExness.stats?.['invitationUrl']" id="invitationUrl" type="text" class="w-full mb-3" readonly />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="activityMemberXm" class="block-content">
      <div class="grid">
        <div class="col-12 sm:col-6">
          <div class="card">
            <h5>Information Xm</h5>
            <label class="block text-900 font-medium mb-2" for="server">Server</label>
            <InputText :value="activityMemberXm.server" id="server" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="login">Login</label>
            <InputText :value="activityMemberXm.login" id="login" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="password">Password</label>
            <InputText :value="activityMemberXm.password" id="password" rows="5" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="connected_at">Connected At</label>
            <InputText :value="activityMemberXm.connected_at ? new Date(activityMemberXm.connected_at).toLocaleString() : ''" id="connected_at" rows="5" class="w-full mb-3" readonly />
            
            <label class="block text-900 font-medium mb-2" for="connecting_at">Connecting At</label>
            <InputText :value="activityMemberXm.connecting_at ? new Date(activityMemberXm.connecting_at).toLocaleString() : ''" id="connecting_at" rows="5" class="w-full mb-3" readonly />
            
            <label class="block text-900 font-medium mb-2" for="reason_connection_failure">Reason connection failure</label>
            <InputText :value="activityMemberXm.reason_connection_failure" id="reason_connection_failure" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="created_at">Created At</label>
            <InputText :value="activityMemberXm.created_at ? new Date(activityMemberXm.created_at).toLocaleString() : ''" id="created_at" rows="5" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="updated_at">Updated At</label>
            <InputText :value="activityMemberXm.updated_at ? new Date(activityMemberXm.updated_at).toLocaleString() : ''" id="updated_at" rows="5" class="w-full mb-3" readonly />

          </div>
        </div>

        <div class="col-12 sm:col-6">
          <div class="card">
            <h5>Stats Xm (#{{ activityMemberXm.id }})</h5>

            <activity-account-status-of-member :data="activityMemberXm" />
            <div class="m-3" />
            
            <label class="block text-900 font-medium mb-2" for="score">Score</label>
            <InputText :value="activityMemberXm.score" id="score" type="text" class="w-full mb-3" readonly />

            <label class="block text-900 font-medium mb-2" for="is_disabled_connect">Disabled to connect</label>
            <InputSwitch v-model="vXm$.is_disabled_connect.$model" id="is_disabled_connect" class="mb-3" />

            <label class="block text-900 font-medium mb-2" for="abs_gain">Abs. Gain (%)</label>
            <InputNumber v-model="vXm$.abs_gain.$model" id="abs_gain" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="['mb-3 w-full', vXm$.abs_gain.$invalid ? 'p-invalid' : '']"/>

            <label class="block text-900 font-medium mb-2" for="drawdown">Drawdown (%)</label>
            <InputNumber v-model="vXm$.drawdown.$model" id="drawdown" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="['mb-3 w-full', vXm$.drawdown.$invalid ? 'p-invalid' : '']"/>

            <label class="block text-900 font-medium mb-2" for="equity">Equity</label>
            <InputNumber v-model="vXm$.equity.$model" id="equity" mode="decimal" :minFractionDigits="2" :maxFractionDigits="2" :class="['mb-3 w-full', vXm$.equity.$invalid ? 'p-invalid' : '']"/>

            <Button label="Save" class="w-full" :loading="loading" @click="save(modelXm)" />

            <div class="m-3" />

            <div class="card">
              <div class="card-body">
                <label class="block text-900 font-medium mb-2" for="gain">Gain</label>
                <InputText :value="activityMemberXm.stats?.['gain']" id="gain" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="absGain">Abs. Gain</label>
                <InputText :value="activityMemberXm.stats?.['absGain']" id="absGain" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="daily">Daily</label>
                <InputText :value="activityMemberXm.stats?.['daily']" id="daily" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="monthly">Monthly</label>
                <InputText :value="activityMemberXm.stats?.['monthly']" id="monthly" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="withdrawals">Withdrawals</label>
                <InputText :value="activityMemberXm.stats?.['withdrawals']" id="withdrawals" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="deposits">Deposits</label>
                <InputText :value="activityMemberXm.stats?.['deposits']" id="deposits" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="interest">Interest</label>
                <InputText :value="activityMemberXm.stats?.['interest']" id="interest" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="profit">Profit</label>
                <InputText :value="activityMemberXm.stats?.['profit']" id="profit" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="balance">Balance</label>
                <InputText :value="activityMemberXm.stats?.['balance']" id="balance" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="drawdown">Drawdown</label>
                <InputText :value="activityMemberXm.stats?.['drawdown']" id="drawdown" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="equity">Equity</label>
                <InputText :value="activityMemberXm.stats?.['equity']" id="equity" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="equityPercent">Equity Percent</label>
                <InputText :value="activityMemberXm.stats?.['equityPercent']" id="equityPercent" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="demo">Demo</label>
                <InputText :value="activityMemberXm.stats?.['demo']" id="demo" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="lastUpdateDate">Last Update Date</label>
                <InputText :value="activityMemberXm.stats?.['lastUpdateDate']" id="lastUpdateDate" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="creationDate">Creation Date</label>
                <InputText :value="activityMemberXm.stats?.['creationDate']" id="creationDate" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="firstTradeDate">First Trade Date</label>
                <InputText :value="activityMemberXm.stats?.['firstTradeDate']" id="firstTradeDate" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="tracking">Tracking</label>
                <InputText :value="activityMemberXm.stats?.['tracking']" id="tracking" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="views">Views</label>
                <InputText :value="activityMemberXm.stats?.['views']" id="views" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="commission">Commission</label>
                <InputText :value="activityMemberXm.stats?.['commission']" id="commission" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="currency">Currency</label>
                <InputText :value="activityMemberXm.stats?.['currency']" id="currency" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="profitFactor">Profit Factor</label>
                <InputText :value="activityMemberXm.stats?.['profitFactor']" id="profitFactor" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="pips">Pips</label>
                <InputText :value="activityMemberXm.stats?.['pips']" id="pips" type="text" class="w-full mb-3" readonly />

                <label class="block text-900 font-medium mb-2" for="invitationUrl">Invitation Url</label>
                <InputText :value="activityMemberXm.stats?.['invitationUrl']" id="invitationUrl" type="text" class="w-full mb-3" readonly />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
